<template>
  <div class="contentBQ top-padding">
    <top-home title="维保信息"></top-home>
    <van-tabs
      v-model="active"
      color="rgb(41, 167, 254)"
      title-active-color="rgb(41, 167, 254)"
    >
      <van-tab title="合同信息">
        <div class="tapOne">
          <div class="left-box">
            <div class="tapOne-div">
              <van-field
                v-model="htxxObj.otherParams.value"
                placeholder="请输入项目名称"
              />
            </div>
            <div>
              <van-button
                type="info"
                size="20px"
                @click="queryDataFun"
              >查询</van-button>
            </div>
          </div>
        </div>
        <div class="bill-list-box">
          <van-list
            v-model="htxxObj.listLoading"
            :finished="htxxObj.listFinished"
            finished-text="没有更多了"
            @load="listOnLoadFun"
          >
            <div
              class="list-item"
              v-for="(item,index) in htxxObj.list"
              :key="index"
              @click="opentDetailsFun(item)"
            >
              <div class="item-content">
                <div class="content-lie">
                  <div class="lie yf">
                    <span>合同编号:</span>
                    {{item.htbh}}
                  </div>
                  <div class="zt">
                    {{getDicDataFun('issign',item.issign)}}
                  </div>
                </div>
                <div class="content-lie">
                  <div class="lie  ellipsis">
                    <span>项目名称:</span>
                    {{item.xmmc}}
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </van-tab>
      <van-tab title="整改报价">
        <div class="tapOne">
          <div class="left-box">
            <div class="tapOne-div">
              <van-field
                v-model="zgbjObj.otherParams.value"
                placeholder="请输入项目名称"
              />
            </div>
            <div>
              <van-button
                type="info"
                size="20px"
                @click="queryDataFun"
              >查询</van-button>
            </div>
          </div>
        </div>
        <div class="bill-list-box">
          <van-list
            v-model="zgbjObj.listLoading"
            :finished="zgbjObj.listFinished"
            finished-text="没有更多了"
            @load="listOnLoadFun"
          >
            <div
              class="list-item"
              v-for="(item,index) in zgbjObj.list"
              :key="index"
              @click="opentDetailsFun(item)"
            >
              <div class="item-content">
                <div class="content-lie">
                  <div class="lie yf">
                    <span>报价编号:</span>
                    {{item.bjbh}}
                  </div>
                  <div class="je">
                    <span>报价金额:</span>
                    ¥ {{item.bjje}}
                  </div>
                </div>
                <div class="content-lie">
                  <div class="lie  ellipsis">
                    <span>项目名称:</span>
                    {{item.xmmc}}
                  </div>
                  <div class="lie ellipsis">
                    <span>客户:</span>
                    {{item.khmc}}
                  </div>
                </div>
                <div class="content-lie">
                  <div class="lie">
                    <span>报价日期:</span>
                    {{item.bjrq}}
                  </div>
                  <div class="zt">
                    <span>报价状态:</span>
                    {{getDicDataFun('bjzt',item.bjzt)}}
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </van-tab>
      <van-tab title="维保异常">
        <div class="tapOne">
          <div class="left-box">
            <div class="tapOne-div">
              <van-field
                v-model="wbycObj.otherParams.value"
                placeholder="请输入项目名称"
              />
            </div>
            <div>
              <van-button
                type="info"
                size="20px"
                @click="queryDataFun"
              >查询</van-button>
            </div>
          </div>
        </div>
        <div class="bill-list-box">
          <van-list
            v-model="wbycObj.listLoading"
            :finished="wbycObj.listFinished"
            finished-text="没有更多了"
            @load="listOnLoadFun"
          >
            <div
              class="list-item"
              v-for="(item,index) in wbycObj.list"
              :key="index"
            >
              <div class="item-content">
                <div class="content-lie">
                  <div style="width:100%">
                    <span>项目名称:</span>
                    {{item.xmmc}}
                  </div>
                </div>
                <div class="content-lie">
                  <div style="width:100%">
                    <span>设备代码:</span>
                    {{item.sbdm}}
                  </div>
                </div>
                <div class="content-lie">
                  <div class="lie  ellipsis">
                    <span>楼号:</span>
                    {{item.lh}}
                  </div>
                  <div class="lie ellipsis">
                    <span>梯号:</span>
                    {{item.th}}
                  </div>
                </div>
                <div class="content-lie">
                  <div class="lie">
                    <span>计划日期:</span>
                    {{item.jhrq}}
                  </div>
                  <div class="zt">
                    <span>结果:</span>
                    {{getDicDataFun('ycjg',item.result)}}
                  </div>
                </div>
                <div class="content-lie">
                  <div style="width:100%">
                    <span>保养项:</span>
                    {{item.content}}
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </van-tab>
      <van-tab title="配件更换">
        <div class="tapOne">
          <div class="left-box">
            <div class="tapOne-div">
              <van-field
                v-model="pjghObj.otherParams.value"
                placeholder="请输入项目名称"
              />
            </div>
            <div>
              <van-button
                type="info"
                size="20px"
                @click="queryDataFun"
              >查询</van-button>
            </div>
          </div>
        </div>
        <div class="bill-list-box">
          <van-list
            v-model="pjghObj.listLoading"
            :finished="pjghObj.listFinished"
            finished-text="没有更多了"
            @load="listOnLoadFun"
          >
            <div
              class="list-item"
              v-for="(item,index) in pjghObj.list"
              :key="index"
              @click="opentDetailsFun(item)"
            >
              <div class="item-content">
                <div class="content-lie">
                  <div class="lie ellipsis">
                    <span>项目名称:</span>
                    {{item.xmmc}}
                  </div>
                  <div class="lie ellipsis">
                    <span>设备代码:</span>
                    {{item.sbdm}}
                  </div>
                </div>
                <div class="content-lie">
                  <div class="lie  ellipsis">
                    <span>楼号:</span>
                    {{item.lh}}
                  </div>
                  <div class="lie ellipsis">
                    <span>梯号:</span>
                    {{item.th}}
                  </div>
                </div>
                <div class="content-lie">
                  <div class="lie ellipsis">
                    <span>配件名称:</span>
                    {{item.pjmc}}
                  </div>
                  <div class="lie ellipsis">
                    <span>配件代码:</span>
                    {{item.pjdm}}
                  </div>
                </div>
                <div class="content-lie">
                  <div class="lie ellipsis">
                    <span>更换数量:</span>
                    {{item.ghsl}}
                  </div>
                  <div class="lie ellipsis">
                    <span>更换日期:</span>
                    {{item.ghrq}}
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </van-tab>
      <van-tab title="反馈内容">
        <div class="tapOne">
          <div class="left-box">
            <div class="tapOne-div">
              <van-field
                v-model="jffkObj.otherParams.value"
                placeholder="请输入项目名称"
              />
            </div>
            <div>
              <van-button
                type="info"
                size="20px"
                @click="queryDataFun"
              >查询</van-button>
            </div>
          </div>
        </div>
        <div class="bill-list-box">
          <van-list
            v-model="jffkObj.listLoading"
            :finished="jffkObj.listFinished"
            finished-text="没有更多了"
            @load="listOnLoadFun"
          >
            <div
              class="list-item"
              v-for="(item,index) in jffkObj.list"
              :key="index"
              @click="opentDetailsFun(item)"
            >
              <div class="item-content">
                <div class="content-lie">
                  <div
                    class="ellipsis"
                    style="width:100%"
                  >
                    <span>反馈内容:</span>
                    {{item.fknr}}
                  </div>
                </div>
                <div class="content-lie">
                  <div style="display:flex;justify-content: space-between;width:100%">
                    <div>
                      <span>类型:</span>
                      {{getDicDataFun('fklx',item.fklx)}}
                    </div>
                    <div>
                      <span>时间:</span>
                      {{item.fksj}}
                    </div>
                    <div>
                      <span>状态:</span>
                      {{getDicDataFun('clzt',item.clzt)}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </van-tab>
      <van-tab title="日常巡检">
        <div class="tapOne">
          <div class="left-box">
            <div class="tapOne-div">
              <van-field
                v-model="rcxjObj.otherParams.value"
                placeholder="请输入设备代码"
              />
            </div>
            <div>
              <van-button
                type="info"
                size="20px"
                @click="queryDataFun"
              >查询</van-button>
            </div>
          </div>
        </div>
        <div class="bill-list-box">
          <van-list
            v-model="rcxjObj.listLoading"
            :finished="rcxjObj.listFinished"
            finished-text="没有更多了"
            @load="listOnLoadFun"
          >
            <div
              class="list-item"
              v-for="(item,index) in rcxjObj.list"
              :key="index"
            >
              <div class="item-content">
                <div class="content-lie">
                  <div style="width:100%">
                    <span>设备代码:</span>
                    {{item.dtsbdm}}
                  </div>
                </div>
                <div class="content-lie">
                  <div class="lie">
                    <span>时间:</span>
                    {{item.xjsj}}
                  </div>
                  <div class="lie">
                    <span>签到距离:</span>
                    {{item.qdjl}}
                  </div>
                </div>
                <div class="content-lie">
                  <div style="width:100%">
                    <span>状态:</span>
                    {{getDicDataFun('yxzt',item.dtyxzt)}}
                  </div>
                </div>
                <div class="content-lie">
                  <div style="width:100%">
                    <span>异常描述:</span>
                    {{item.ycms}}
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </van-tab>
    </van-tabs>
    <van-popup
      closeable
      v-model="isShowDetails"
      position="right"
      :style="{ width:'100%',height: '100%' }"
    >
      <iframe
        class="iframe-view"
        ref="iframe_view"
        v-if="isShowDetails"
        :src="iframeViewUrl"
      ></iframe>
    </van-popup>
  </div>
</template>

<script>
import config from '@/config'
import { mapGetters } from 'vuex'
import { getDicTableData, getComputerTableApi } from '@/reuqest/api.js'

export default {
  computed: {
    ...mapGetters(['token']),
    iframeViewUrl () {
      console.log(`${config.PC_FORM_VISIT_URL}/wx_${this.code}?token=${this.token}&curr_details_id=${this.currDetailsId}`)
      return `${config.PC_FORM_VISIT_URL}/wx_${this.code}?token=${this.token}&curr_details_id=${this.currDetailsId}`
    },
  },
  data () {
    return {
      active: '',
      code: '',
      currDetailsId: '',
      isShowDetails: false,
      htxxObj: {//整改报价
        tablePage: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
        list: [],
        listLoading: false,
        listFinished: false,
        getDataThrottle: false,
        otherParams: {
          key: 'xmmc',
          value: '',
        },
        tableId: '1561979575091335169',
        formCode: 'htxx'
      },
      zgbjObj: {//整改报价
        tablePage: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
        list: [],
        listLoading: false,
        listFinished: false,
        getDataThrottle: false,
        otherParams: {
          key: 'xmmc',
          value: '',
        },
        tableId: '1561975653643587585',
        formCode: 'zgbj'
      },
      wbycObj: {//维保异常
        tablePage: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
        list: [],
        listLoading: false,
        listFinished: false,
        getDataThrottle: false,
        otherParams: {
          key: 'xmmc',
          value: ''
        },
        tableId: '1561978134800896002',
        formCode: 'wbyc'
      },
      pjghObj: {//配件更换
        tablePage: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
        list: [],
        listLoading: false,
        listFinished: false,
        getDataThrottle: false,
        otherParams: {
          key: 'xmmc',
          value: ''
        },
        tableId: '1561981535928455169',
        formCode: 'pjgh'
      },
      jffkObj: {//反馈内容
        tablePage: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
        list: [],
        listLoading: false,
        listFinished: false,
        getDataThrottle: false,
        otherParams: {
          key: 'xmmc',
          value: ''
        },
        tableId: '1561985262768893954',
        formCode: 'jffk'
      },
      rcxjObj: {//日常巡检
        tablePage: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
        list: [],
        listLoading: false,
        listFinished: false,
        getDataThrottle: false,
        otherParams: {
          key: 'dtsbdm',
          value: ''
        },
        tableId: '1561972892487188481',
        formCode: 'rcxj'
      },
      searchParams: {
        pageSize: 10,
        order: 'desc',
        column: 'id'
      },
      allDicData: {},//字典数据
    }
  },
  filters: {},
  created () {
    this.active = parseInt(this.$route.query.active)
    getDicTableData('hyb_xsht_xsbj_bjzt').then(res => {
      this.allDicData.bjztDic = res.data
    }).catch(() => { })
    getDicTableData('wbb_cygj_jg').then(res => {
      this.allDicData.ycjgDic = res.data
    }).catch(() => { })
    getDicTableData('hyb_htgl_clzt').then(res => {
      this.allDicData.clztDic = res.data
    }).catch(() => { })
    getDicTableData('hyb_fklx').then(res => {
      this.allDicData.fklxDic = res.data
    }).catch(() => { })
    getDicTableData('hyb_wbht_dtxx_dtyxzt').then(res => {
      this.allDicData.yxztDic = res.data
    }).catch(() => { })
    getDicTableData('sign_zt').then(res => {
      this.allDicData.issignDic = res.data
    }).catch(() => { })
  },
  mounted () {
    window.addEventListener('message', this.handleMessage, true)
  },
  destroyed () {
    window.removeEventListener('message', this.handleMessage, true)
  },
  methods: {
    //获取账单list
    async listOnLoadFun () {
      let tabType = this.getTabFun()
      this[`${tabType}Obj`].listLoading = true
      if (this[`${tabType}Obj`].getDataThrottle == false) {
        this[`${tabType}Obj`].getDataThrottle = true
        this.getDataTimer = setTimeout(() => {
          return new Promise((resolve) => {
            let params = {
              ...this.searchParams,
              pageNo: this[`${tabType}Obj`].tablePage.pageNo
            }
            if (this[`${tabType}Obj`].otherParams.value) {
              params[this[`${tabType}Obj`].otherParams.key] = this[`${tabType}Obj`].otherParams.value
            }
            getComputerTableApi(this[`${tabType}Obj`].tableId, params).then((res) => {
              let data = res.data
              if (data.records && data.records.length > 0) {
                this[`${tabType}Obj`].list = [...this[`${tabType}Obj`].list, ...data.records]
                this[`${tabType}Obj`].tablePage.total = data.total
                this[`${tabType}Obj`].tablePage.pageNo = this[`${tabType}Obj`].tablePage.pageNo + 1
              } else if (data.total == 0) {
                this[`${tabType}Obj`].list = []
                this[`${tabType}Obj`].tablePage.total = 0
              }
              if (this[`${tabType}Obj`].list.length >= this[`${tabType}Obj`].tablePage.total) {
                this[`${tabType}Obj`].listFinished = true
              } else {
                this[`${tabType}Obj`].listFinished = false
              }
              this[`${tabType}Obj`].listLoading = false
              this[`${tabType}Obj`].getDataThrottle = false
              resolve()
            })
          })
        }, 1000)
      }
    },
    queryDataFun () {
      //重置参数
      let tabType = this.getTabFun()
      this[`${tabType}Obj`].tablePage.pageNo = 1
      this[`${tabType}Obj`].tablePage.total = 0
      let params = {
        ...this.searchParams,
        pageNo: 1,
      }
      if (this[`${tabType}Obj`].otherParams.value) {
        params[this[`${tabType}Obj`].otherParams.key] = this[`${tabType}Obj`].otherParams.value
      }
      getComputerTableApi(this[`${tabType}Obj`].tableId, params).then((res) => {
        let data = res.data
        if (data.records && data.records.length > 0) {
          this[`${tabType}Obj`].list = data.records
          this[`${tabType}Obj`].tablePage.total = data.total
          this[`${tabType}Obj`].tablePage.pageNo = this[`${tabType}Obj`].tablePage.pageNo + 1
        } else if (data.total == 0) {
          this[`${tabType}Obj`].list = []
          this[`${tabType}Obj`].tablePage.total = 0
        }
        if (this[`${tabType}Obj`].list.length < 10) {
          this[`${tabType}Obj`].listFinished = true
        } else {
          this[`${tabType}Obj`].listFinished = false
        }
        this[`${tabType}Obj`].listLoading = false
      })
    },
    getDicDataFun (key, val) {
      let text = ''
      this.allDicData[`${key}Dic`].forEach(item => {
        if (item.value == val) {
          text = item.label
        }
      })
      return text
    },
    getTabFun () {
      let tab = ''
      if (this.active == 0) {
        tab = 'htxx'
      } else if (this.active == 1) {
        tab = 'zgbj'
      } else if (this.active == 2) {
        tab = 'wbyc'
      } else if (this.active == 3) {
        tab = 'pjgh'
      } else if (this.active == 4) {
        tab = 'jffk'
      } else if (this.active == 5) {
        tab = 'rcxj'
      }
      return tab
    },
    opentDetailsFun (item) {
      this.code = this.getTabFun()
      this.currDetailsId = item.id
      this.isShowDetails = true
    },
    handleMessage (event) {
      const data = event.data
      if (data.cmd == 'setHeight') {
        document.querySelector('.iframe-view').style.height =
          data.params.data.height + 'px'
      }
    },
  },
}
</script>
 
<style lang="less" scoped>
.contentBQ {
  height: 100vh;
  background-color: rgb(245, 245, 245);
}
.iframe-view {
  width: 100%;
  min-height: 100%;
  border: 0;
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box;
}
.tapOne {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  .left-box {
    display: flex;
    align-items: center;
  }
  /deep/.tapOne-div {
    margin-right: 6px;
    .van-cell {
      padding: 3px;
      font-size: 14px;
    }
  }
}
.van-button {
  height: 100%;
  padding: 5px 10px;
}
.bill-list-box {
  background-color: #f5f5f5;
  .list-item {
    background-color: #fff;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 5px 0;
    .item-img {
      margin: 0 10px;
      img {
        width: 45px;
      }
    }
    .item-content {
      width: 100%;
      padding: 8px;
      .je {
        color: #ffb54c;
        line-height: 22px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .content-lie {
        display: flex;
        align-items: center;
        font-size: 14px;
        padding: 3px 0;
        color: #232323;
        text-align: left;
        .lie {
          width: 50%;
        }

        span {
          color: #999;
        }
      }
    }
  }
}
.ellipsis {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>